<template>
  <v-card
    color="element"
    class="mx-auto py-5 px-5"
    max-width="600"
  >
    <h3 class="font-weight-medium">{{$t('onboarding.investmentSurvey.views.services.title') }}</h3>
    <p class="pt-3">
      {{ $t('onboarding.investmentSurvey.views.services.experienceQuestion') }}
    </p>
    <br><br>
    <v-form
      @submit.prevent="submit"
    >
      <div v-if="serviceMode">
        <div
          v-for="service in services"
          :key="service"
          class="pb-12"
        >
          <p> {{ $t(`onboarding.investmentSurvey.services.${service}`) }}</p>
          <v-slider
            :key="`slider${service}`"
            v-model="serviceValues[service]"
            :tick-labels="tickLabels(service)"
            :max="2"
            step="1"
            ticks="always"
            tick-size="4"
          ></v-slider>
        </div>
      </div>
      <div v-else>
        <div
          v-for="transaction in transactions"
          :key="transaction"
          class="pb-12"
        >
          <p> {{ $t(`onboarding.investmentSurvey.services.${transaction}`) }}</p>
          <v-slider
            :key="`slider${transaction}`"
            v-model="transactionValues[transaction]"
            :tick-labels="tickLabels(transaction)"
            :max="3"
            step="1"
            ticks="always"
            tick-size="4"
          ></v-slider>
        </div>
      </div>
    </v-form>
    <!-- <SkipInvestmentSurvey /> -->
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import lazyLoad from '@/utils/lazyLoad';
import clone from '@/utils/clone';
import { mapFrontendValueToDbEnum, mapDbEnumToFrontendValue } from '@/mappings/onboarding/investmentSurvey';

export default {
  name: 'InvestmentSurveyServices',
  // components: {
  //   SkipInvestmentSurvey: lazyLoad('components/Onboarding/SkipInvestmentSurvey'),
  // },
  data() {
    return {
      data: clone(this.$store.state.user.data),
      serviceValues: {
        independent: 0,
        investmentConsultancy: 0,
        investmentManagement: 0,
      },
      serviceMode: true,
      transactions: ['avgTransactionValue', 'avgYearlyTransactions'],
      transactionValues: {
        avgTransactionValue: 0,
        avgYearlyTransactions: 0,
      },
    };
  },
  computed: {
    ...mapGetters('onboarding', [
      'experiences',
      'transactionNumber',
      'transactionVolume',
      'serviceExperienceDone',
    ]),
    services() {
      return Object.keys(this.data.investmentSurvey.serviceExperience);
    },
    independentValue() {
      return this.serviceValues.independent;
    },
    investmentConsultancyValue() {
      return this.serviceValues.investmentConsultancy;
    },
    investmentManagementValue() {
      return this.serviceValues.investmentManagement;
    },
    avgTransactionValue() {
      return this.transactionValues.avgTransactionValue;
    },
    avgYearlyTransactions() {
      return this.transactionValues.avgYearlyTransactions;
    },
  },
  watch: {
    independentValue(value) {
      this.watchServiceSliderValue('independent', value);
    },
    investmentConsultancyValue(value) {
      this.watchServiceSliderValue('investmentConsultancy', value);
    },
    investmentManagementValue(value) {
      this.watchServiceSliderValue('investmentManagement', value);
    },
    avgTransactionValue(value) {
      this.watchTransactionSliderValue('avgTransactionValue', value);
    },
    avgYearlyTransactions(value) {
      this.watchTransactionSliderValue('avgYearlyTransactions', value);
    },
  },
  created() {
    if (this.serviceExperienceDone) {
      this.serviceMode = false;
    }
    this.services.forEach((service) => {
      this.checkServiceExperiences(service);
    });
    this.checkTransactionNumber();
    this.checkTransactionVolume();
  },
  methods: {
    ...mapActions('user', [
      'updateUser',
      'setSubStage',
      'updateOnboarding',
      'previousStep',
    ]),
    ...mapActions('onboarding', [
      'setServiceExperienceDone',
    ]),
    tickLabels(type) {
      if (type === 'avgYearlyTransactions') {
        return this.transactionNumber.map((e) => e.text);
      }
      if (type === 'avgTransactionValue') {
        return this.transactionVolume.map((e) => e.text);
      }
      return this.experiences.map((e) => e.text);
    },
    watchTransactionSliderValue(data, value) {
      this.data.investmentSurvey[data] = mapFrontendValueToDbEnum[data][value];
    },
    watchServiceSliderValue(data, value) {
      this.data.investmentSurvey.serviceExperience[data] = mapFrontendValueToDbEnum.rangeOfExperience[value];
    },
    checkServiceExperiences(data) {
      this.serviceValues[data] = mapDbEnumToFrontendValue
        .rangeOfExperience[this.data.investmentSurvey.serviceExperience[data]];
    },
    checkTransactionNumber() {
      this.transactionValues.avgYearlyTransactions = mapDbEnumToFrontendValue
        .avgYearlyTransactions[this.data.investmentSurvey.avgYearlyTransactions];
    },
    checkTransactionVolume() {
      this.transactionValues.avgTransactionValue = mapDbEnumToFrontendValue
        .avgTransactionValue[this.data.investmentSurvey.avgTransactionValue];
    },
    previous(step) {
      if (!this.serviceMode) {
        this.serviceMode = true;
      } else {
        this.setServiceExperienceDone(false);
        this.setSubStage(step);
        this.previousStep();
      }
    },
    async submit() {
      if (this.serviceMode) {
        this.serviceMode = false;
      } else {
        try {
          const input = {
            investor: {
              investmentSurvey: {
                services: {
                  serviceExperience: this.data.investmentSurvey.serviceExperience,
                  avgYearlyTransactions: this.data.investmentSurvey.avgYearlyTransactions,
                  avgTransactionValue: this.data.investmentSurvey.avgTransactionValue,
                },
              },
            },
            mainStage: 'investmentSurvey',
            subStage: 'services',
          };
          await this.updateOnboarding({
            input,
            newData: this.data,
          });
          this.setServiceExperienceDone(true);
          this.setSubStage('overview');
        } catch (error) {
          this.$notify.error(error.message);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@media (max-width: 456px) {
  .v-slider__tick-label {
    font-size: 10px !important;
  }
}
</style>
